import { create } from 'zustand';

const variant = create<{
  variantName: string;
  variantType: string;
  variantId: string;
  setVariantName: (variantName: string) => void;
  setVariantType: (variantType: string) => void;
  setVariantId: (variantId: string) => void;
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
}>((set) => ({
  variantType: 'default',
  variantName: '',
  variantId: '',
  loggedIn: false,
  setVariantName: (variantName: string) => {
    set({ variantName });
  },
  setVariantType: (variantType: string) => {
    set({ variantType });
  },
  setVariantId: (variantId: string) => {
    set({ variantId });
  },
  setLoggedIn: (loggedIn: boolean) => {
    set({ loggedIn });
  },
}));

export default variant;
