import analyticsStore from './Analytics.store';

const getBaseTrackingData = () => {
  return {
    client: 'marketing site',
    page: window.location.pathname,
    query_param: window.location.search,
    variantName: analyticsStore.getState().variantName,
    variantType: analyticsStore.getState().variantType,
    variantId: analyticsStore.getState().variantId,
    loggedIn: analyticsStore.getState().loggedIn.toString(),
  };
};

export default getBaseTrackingData;
